import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const storedUser = Cookies.get('userData');
    const initialUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;
    const [user, setUser] = useState(initialUser);
    const storedPermissions = Cookies.get('userPermissions');
    const initialPermissions = storedPermissions && storedPermissions !== "undefined" ? JSON.parse(storedPermissions) : [];
    const [currentUserPermissions, setCurrentUserPermissions] = useState(initialPermissions);
    const [userFlag, setUserFlag] = useState("user");

    useEffect(() => {
        if (!storedUser) {
            return;
        }
        if (initialUser && initialUser.token) {
            try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
                setUserFlag(parsedUser?.user?.flag);
            } catch (error) {
                console.error('Error parsing user data:', error);
                Cookies.remove('userData');
            }
        }
    }, []);

    const login = (userData, rememberMe, permissions) => {
        const userDataString = JSON.stringify(userData);
        const permissionsString = JSON.stringify(permissions);
        Cookies.set('userData', userDataString, { expires: rememberMe ? 365 : null });
        Cookies.set('userPermissions', permissionsString, {expires: rememberMe ? 365 : null})
        setUser(userData);
    };

    const logout = () => {
        Cookies.remove('userData');
        Cookies.remove('userPermissions');
        window.location.reload();
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, setCurrentUserPermissions, currentUserPermissions, userFlag, setUserFlag, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
