import apiRequest from "../../utils/apiRequest";
import {toast} from "react-toastify";

const createAdminMember = async (memberData) => {
    return apiRequest("admin/member", "post", memberData);
};

const deleteAdminMemberApi = async (id) => {
    return apiRequest(`admin/member/${id}`, "delete");
};

const editAdminMemberApi = async (id, updatedData) => {
    const response = await apiRequest(`admin/member/${id}`, "put", updatedData);
    if (response?.data?.message) {
        toast.success(response?.data?.message, {
            position: "bottom-right",
            theme: "dark",
        });
    }
    return response;
};

const updateAdminMemberStatusApi = async (id) => {
    const response = await apiRequest(`admin/client/${id}`, "delete");
    toast.success("Member updated successfully", { position: "bottom-right", theme: "dark" });
    return response;
};


// Statistics
const getSingleAdminMemberApi = async (id) => {
    return await apiRequest(`admin/member/${id}`, "get");
};

const getAllAdminMembersApi = async () => {
    return await apiRequest("admin/member", "get");
};

const getTodayClients = async () => {
    return await apiRequest("admin/today_clients", "get");
};

const getTodayLeads = async () => {
    return await apiRequest("admin/today_Leads", "get");
};

const getTodayCompletedLeads = async () => {
    return await apiRequest("admin/today_Completed_Leads", "get");
};

export default {
    createAdminMember,
    deleteAdminMemberApi,
    editAdminMemberApi,
    updateAdminMemberStatusApi,
    getSingleAdminMemberApi,
    getAllAdminMembersApi,
    getTodayClients,
    getTodayLeads,
    getTodayCompletedLeads,
};