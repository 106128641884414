import apiRequest from "../../../utils/apiRequest";

const CheckPhoneApi = async (signUpData) => {
    return await apiRequest("check-phone", "post", signUpData);
};

const DisconnectFromFBApi = async (FBAccID) => {
    const data = { user_id: FBAccID?.toString() };
    try {
        return await apiRequest("disconnect", "post", data); // Just return the data, no need to return the entire response object
    } catch (error) {
        console.error("Error disconnecting from Facebook:", error);
        throw error;
    }
};

const getAccountInfoAPI = async (signInData) => {
    return await apiRequest("profile", "post", signInData); // Just return the data, no need for the full response object
};

const SignInApi = async (signInData) => {
    return await apiRequest("login", "post", signInData);
};

const SignOutApi = async () => {
    return await apiRequest("logout", "post");
};

const SignUpApi = async (registerData) => {
    return await apiRequest("register", "post", registerData);
};

const TikTokRedirectApi = async (code) => {
    return await apiRequest("tiktokaccesstoken", "post", {code});
};

const TikTokRequestTokenApi = async () => {
    return await apiRequest("auth/tiktok", "get");
};

const updateProfileApi = (formData) => {
    return apiRequest("update-profile", "post", formData, {
        "Content-Type": "multipart/form-data",
    });
};

export default { CheckPhoneApi, DisconnectFromFBApi, getAccountInfoAPI, SignInApi, SignOutApi, SignUpApi, TikTokRedirectApi, TikTokRequestTokenApi, updateProfileApi };