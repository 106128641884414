import apiRequest from "../../../utils/apiRequest";
import {showErrorToast} from "../../../utils/toast-success-error";

const getChatsForPageApi = async (data) => {
    const url = "chats";
    return await apiRequest(url, "post", data, {
        "Content-Type": "application/json",
    }, data?.signal);
};

const getAccessTokenApi = async () => {
    const url = "get/access_token";
    return await apiRequest(url, "post");
};

const getAccountsApi = async (token) => {
    const url = "accounts";
    const data = { token };
    return await apiRequest(url, "post", data);
};

const getFormsApi = async (data) => {
    const url = "forms";
    return await apiRequest(url, "post", data);
};

const getFormsLeadsApi = async (data) => {
    const url = "leads";
    return await apiRequest(url, "post", data);
};

const getPageImgAPI = async (data) => {
    const url = "page_image";
    return await apiRequest(url, "post", data);
};

const getLeadsByPageApi = async (data) => {
    const url = "get_leads_by_page";
    return await apiRequest(url, "post", data);
};

const getMessagesForChatApi = async (data) => {
    const { signal, ...requestData } = data;
    return await apiRequest("messages", "post", requestData, { signal });
};

const getMoreChatsAPI = async (data) => {
    const { signal, ...requestData } = data;
    return await apiRequest("chats_pagination", "post", requestData, { signal });
};

const deleteAccessToken = async () => {
    return await apiRequest("remove-access-token", "post");
};

const sendMessageApi = async (data) => {
    try {
        return await apiRequest("send-message", "post", data);
    } catch (error) {
        showErrorToast(error?.response?.data?.message || "Error sending message");
        throw error;
    }
};

const sendWhatsMessageApi = async (data) => {
    try {
        return await apiRequest("whatsApp-send", "post", data);
    } catch (error) {
        showErrorToast(error?.response?.data?.message || "Error sending WhatsApp message");
        throw error;
    }
};

const updateAccessTokenApi = async (leadData) => {
    return await apiRequest("access_token", "post", leadData);
};

const getUserPagesApi = async ({ signal }) => {
    return await apiRequest("userPages", "post", null, null, signal);
};

const getWhatsAppAccountDetails = async (params) => {
    const data = {
        access_token: params.accessToken,
        id: params.id,
    };

    try {
        return await apiRequest("get_accounts_datails", "post", data);
    } catch (error) {
        console.error("Error sending data:", error);
        showErrorToast(error?.response?.data?.message || "An error occurred");
        throw error;
    }
};

const getWhatsAppAccounts = async (accessToken) => {
    const data = {
        access_token: accessToken,
    };

    try {
        return await apiRequest("get_All_accounts", "post", data);
    } catch (error) {
        console.error("Error sending data:", error);
        showErrorToast(error?.response?.data?.message || "An error occurred");
        throw error;
    }
};

const formatDateString = (dateString) => dateString.replace("T", " ");

const getWhatsAppFilterConversations = async (params) => {
    const data = {
        access_token: params.accessToken,
        id: params.id,
        from: formatDateString(params.startDate),
        to: formatDateString(params.endDate),
    };

    try {
        return await apiRequest("filter_details", "post", data);
    } catch (error) {
        console.error("Error sending data:", error);
        showErrorToast(error?.response?.data?.message || "An error occurred");
        throw error;
    }
};

export default {
    getChatsForPageApi,
    getAccessTokenApi,
    getAccountsApi,
    getFormsApi,
    getFormsLeadsApi,
    getPageImgAPI,
    getLeadsByPageApi,
    getMessagesForChatApi,
    getMoreChatsAPI,
    deleteAccessToken,
    sendMessageApi,
    sendWhatsMessageApi,
    updateAccessTokenApi,
    getUserPagesApi,
    getWhatsAppAccountDetails,
    getWhatsAppAccounts,
    getWhatsAppFilterConversations,
};