const AdditionalData = ({ leadDetails }) => {
    const additionalDataString = leadDetails?.additional_data;
    let additionalData = {};

    // Parse additional data string to object if it exists
    if (additionalDataString) {
        try {
            additionalData = JSON.parse(additionalDataString);
        } catch (error) {
            console.error('Error parsing additional data:', error);
        }
    }

    return (
        <div className={"about-contact mb-3"}>
            <div className={"fw-bold mainColor fs-6 mb-3"}>
                Additional Data
            </div>
            {Object.keys(additionalData).length > 0 ? (
                <div className={"fs-6 opacity-50"} style={{ direction: "rtl" }}>
                    <ul>
                        {Object.entries(additionalData).map(([key, value]) => (
                            <li key={key}>
                                {typeof value === 'object' ? (
                                    <>
                                        <span className={"fw-bold"}>{key}:</span>
                                        <ul>
                                            {Object.entries(value).map(([innerKey, innerValue]) => (
                                                <li key={innerKey}>
                                                    <span className={"fw-bold"}>{innerKey}:</span>
                                                    <br/>
                                                    {innerValue}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <span className={"fw-bold"}>{key}:</span>
                                        <br/>
                                        {value}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>No additional data available.</div>
            )}
            {!Object.keys(additionalData).length && (
                <div className={"invalid-data"}>
                    Invalid additional data format.
                </div>
            )}
        </div>
    );
};

export default AdditionalData;