import { createContext, useContext, useState } from 'react';
// Step 1: Create the Context
const LeadsPaginationContext = createContext();

// Step 2: Define the Provider Component
export const LeadsPaginationProvider = ({ children }) => {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [total, setTotal] = useState(0);
    const [recordsToDisplay, setRecordsToDisplay] = useState(10); // Default value

    // Step 3: Provide the context values
    const contextValues = {
        totalPages,
        setTotalPages,
        currentPage,
        setCurrentPage,
        recordsPerPage,
        setRecordsPerPage,
        paginationLinks,
        setPaginationLinks,
        total,
        setTotal,
        recordsToDisplay,
        setRecordsToDisplay
    };

    return (
        <LeadsPaginationContext.Provider value={contextValues}>
            {children}
        </LeadsPaginationContext.Provider>
    );
};

// Custom hook to consume the context
export const useLeadsPaginationContext = () => useContext(LeadsPaginationContext);