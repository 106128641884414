import { api } from "../services/config";
import { showErrorToast } from "./toast-success-error";

const apiRequest = async (url, method = "get", data = null, headers = {}, signal = null, additionalConfig = {}) => {
    const config = {
        url,
        method,
        headers: { "Content-Type": "application/json", ...headers },
        signal,
        ...additionalConfig, // Allow overriding or adding Axios configurations dynamically
    };

    // Handle query params for GET requests
    if (method === "get" && data) {
        config.params = data;
    } else if (data && headers["Content-Type"] === "multipart/form-data") {
        config.data = data;
    } else if (data && method !== "get") {
        config.data = data;
    }

    try {
        const response = await api(config);
        return response.data;
    } catch (error) {
        if (signal?.aborted) {
            // Suppress toast for canceled requests, just log to console
            console.warn(`Request to ${url} was aborted.`);
        } else {
            const errorMessage =
                error?.response?.data?.message || error.message || "An unexpected error occurred";
            showErrorToast(errorMessage); // Show toast only for non-canceled errors
            console.error(`Error in API Request [${method.toUpperCase()} ${url}]:`, error);
        }
        throw error;
    }
};

export default apiRequest;
