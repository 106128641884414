import apiRequest from "../../../utils/apiRequest";
import leadService from "../../leads";
import {showErrorToast, showSuccessToast} from "../../../utils/toast-success-error";

const authenticationTikTokAPI = async (auth_code) => {
    const data = {
        app_id: process.env.REACT_APP_TIKTOK_APP_ID,
        auth_code: auth_code,
        secret: process.env.REACT_APP_SECRET,
    };

    try {
        return await apiRequest(
            "https://business-api.tiktok.com/open_api/v1.3/oauth2/access_token/",
            "post",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    } catch (error) {
        console.error("Error sending data:", error);
        throw error;
    }
};

const disconnectFromTikTokAPI = async () => {
    return await apiRequest("tiktok/disconnect", "post");
};

const downloadTikTokLeadsAPI = async (advertiserID, pageID) => {
    try {
        // Step 1: Download Excel file
        const response = await apiRequest(
            `download_leads?advertiser_id=${advertiserID}&page_id=${pageID}`,
            "get",
            null,
            { "Content-Type": "multipart/form-data" },
        );

        // Step 2: Create FormData object for upload
        const formData = new FormData();
        formData.append(
            "file",
            response,
            `leads-${new Date().toISOString()}.xlsx`,
        );

        // Step 3: Upload file
        const uploadResponse = await leadService.importLeads(formData);

        if (uploadResponse?.status !== 200) {
            throw new Error(
                `Failed to upload file: ${uploadResponse.status} ${uploadResponse.statusText}`,
            );
        }

        // Step 4: Show success toast
        showSuccessToast(uploadResponse?.data?.message || "Upload successful");

        return response; // Returning the response if needed
    } catch (error) {
        console.error("Error downloading or uploading leads:", error);
        showErrorToast("Error downloading or uploading leads");
        throw error;
    }
};

const getAccessTokenTikTok = async (TikTokToken) => {
    const data = { tiktok_token: TikTokToken };
    return await apiRequest("tiktok/token", "post", data);
};

const checkAccessTokenTikTok = async () => {
    return await apiRequest("check_tiktok_token", "get");
};

const getAdvertisers = async () => {
    return await apiRequest("get_advertisores", "get");
};

const getUserPagesTikTokAPI = async (advertiserID) => {
    return await apiRequest(`get_pages?advertiser_id=${advertiserID}`, "get");
};

const updateAccessToken = async (accessToken) => {
    return await apiRequest("tiktok/token", "post", {tiktok_token: accessToken});
};

export default {
    authenticationTikTokAPI,
    disconnectFromTikTokAPI,
    downloadTikTokLeadsAPI,
    getAccessTokenTikTok,
    checkAccessTokenTikTok,
    getAdvertisers,
    getUserPagesTikTokAPI,
    updateAccessToken
}