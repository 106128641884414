import apiRequest from "../../utils/apiRequest";
import {showErrorToast, showSuccessToast} from "../../utils/toast-success-error";

const addClientApi = (clientData) => {
    return apiRequest("admin/client", "post", clientData);
};

const deleteClientApi = (id) => {
    return apiRequest(`admin/client/${id}`, "delete");
};

const editClientApi = async (clientId, clientData) => {
    try {
        const response = await apiRequest(`admin/client/${clientId}`, "put", clientData);

        showSuccessToast(response.message);
        return response;
    } catch (error) {
        showErrorToast(error?.response?.data?.message);
        throw error;
    }
};

const exportClientsApi = async () => {
    try {
        const response = await apiRequest("admin/clients/export", "get", null, {
            responseType: "blob", // Set the response type to 'blob' to handle binary data
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });

        // Create a Blob object from the response data
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Generate a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx'); // Set the filename for download
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
    } catch (error) {
        showErrorToast('Error exporting data');
        throw error;
    }
};

const getAllClientsApi = async (currentPage, recordsPerPage) => {
    const url = currentPage && recordsPerPage
        ? `admin/client?per_page=${recordsPerPage}&current_page=${currentPage}`
        : "admin/client";

    return await apiRequest(url, "get");
};

const showClientDetails = async (clientId) => {
    const url = `admin/client/${clientId}`;
    return await apiRequest(url, "get");
};

export default {
    addClientApi,
    deleteClientApi,
    editClientApi,
    exportClientsApi,
    getAllClientsApi,
    showClientDetails
}