import { createContext, useContext, useState } from "react";
import { FaMeta, FaTiktok } from "react-icons/fa6";
import {
  FaInstagram,
  FaLinkedin,
  FaSnapchatGhost,
  FaTwitter,
} from "react-icons/fa";
import {
  disconnectFromFB,
  FacebookAuth,
  GoogleAuth,
} from "../utils/firebase.config";
import { FcGoogle } from "react-icons/fc";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import metaService from "../services/integrations/meta";
import tiktokService from "../services/integrations/tiktok";

// Step 1: Create the Context
const IntegrationContext = createContext();

// Step 2: Define the Provider Component
export const IntegrationProvider = ({ children }) => {
  // Define your states and functions here
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [tiktokIntegrated, setTiktokIntegrated] = useState(null);
  const navigate = useNavigate();
  const [showCancelIntegrationModal, setShowCancelIntegrationModal] =
    useState(false);
  const [showCancelIntegrationModal2, setShowCancelIntegrationModal2] =
    useState(false);
  const [integratedAlready, setIntegratedAlready] = useState(null);
  const [tiktokPages, setTiktokPages] = useState([]);
  const [loadingPages, setLoadingPages] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [showTikTokAuthModal, setShowTikTokAuthModal] = useState(false);
  const [userPages, setUserPages] = useState([]);
  const integrationData = [
    {
      id: 1,
      icon: <FaMeta color="#1877f2" size={60} />,
      successIcon: <FaMeta style={{ color: "#92C020" }} size={80} />,
      platform: "Meta",
      description:
        "Receive new leads from Meta lead ads in your DV Connect account",
    },
    {
      id: 2,
      icon: <FaTiktok color="#00000" size={60} />,
      successIcon: <FaMeta style={{ color: "#92C020" }} size={80} />,
      platform: "Tiktok",
      description:
        "Receive new leads from TikTok lead ads in your DV Connect account",
    },
    {
      id: 3,
      icon: <FcGoogle color="#1877f2" size={46} />,
      successIcon: <FcGoogle style={{ color: "#92C020" }} size={80} />,
      platform: "Google",
      description:
        "Receive new leads from Google lead ads in your DV Connect account",
    },
    {
      id: 4,
      icon: <FaLinkedin color="#1877f2" size={46} />,
      successIcon: <FaLinkedin style={{ color: "#92C020" }} size={80} />,
      platform: "LinkedIn",
      description:
        "Receive new leads from LinkedIn lead ads in your DV Connect account",
    },
    {
      id: 5,
      icon: <FaInstagram color="#e4405f" size={46} />,
      successIcon: <FaInstagram style={{ color: "#92C020" }} size={80} />,
      platform: "Instagram",
      description:
        "Receive new leads from Facebook and Instagram lead ads in your DV Connect account",
    },
    {
      id: 6,
      icon: <FaTwitter color="#1da1f2" size={46} />,
      successIcon: <FaTwitter style={{ color: "#92C020" }} size={80} />,
      platform: "Twitter",
      description:
        "Receive new leads from Twitter ads in your DV Connect account",
    },
    {
      id: 7,
      icon: <FaSnapchatGhost color="#fffc00" size={46} />,
      successIcon: <FaSnapchatGhost style={{ color: "#92C020" }} size={80} />,
      platform: "Snapchat",
      description:
        "Receive new leads from Snapchat ads in your DV Connect account",
    },
  ];

  const handleSignInWithPlatform = async (platform) => {
    try {
      const userCredential = await authenticatePlatform(platform);
      if (!userCredential) return;

      const { user, _tokenResponse } = userCredential;
      if (user && _tokenResponse?.oauthAccessToken) {
        const userData = buildUserData(user.email, _tokenResponse.oauthAccessToken, platform);
        await handleUserAuthentication(userData, _tokenResponse);
      } else {
        console.log("Invalid user or token response.");
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

// Helper function to handle platform-specific authentication
  const authenticatePlatform = async (platform) => {
    switch (platform) {
      case "Google":
        return await GoogleAuth();
      case "Facebook":
        return await FacebookAuth();
      case "Tiktok":
        console.log("tiktok");
        return null;
      default:
        return await FacebookAuth();
    }
  };

// Helper function to build user data for API request
  const buildUserData = (email, accessToken, platform) => ({
    username: email,
    access_token: accessToken,
    flag: platform === "Google" ? "g" : "fb",
  });

// Helper function to manage user authentication, token updates, and navigation
  const handleUserAuthentication = async (userData, _tokenResponse) => {
    const [response, result] = await Promise.allSettled([metaService.updateAccessTokenApi(userData), metaService.getUserPagesApi({ signal: new AbortController().signal })]);
    Cookies.set("userPages", JSON.stringify(result.value));

    if (result?.value?.message === "success") {
      setUserPages(result.value);
    }

    if (response?.status === "fulfilled" && response.value?.success) {
      await updateLocalUserData(_tokenResponse.oauthAccessToken);
      setIntegratedAlready(true);

      toast.success(response?.value?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });

      navigateOrReload("/integrations/facebook/connected");
    } else {
      toast.error(response?.value?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
      navigateOrReload("/integrations/facebook/connected");
    }
  };

// Helper function to update local user data in cookies
  const updateLocalUserData = async (accessToken) => {
    const storedUserData = Cookies.get("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (parsedUserData?.user) {
        parsedUserData.user["access-token"] = accessToken;
        Cookies.set("userData", JSON.stringify(parsedUserData));
        Cookies.set("access_token", accessToken);
      } else {
        console.log("User data structure is invalid.");
      }
    } else {
      console.log("User data not found in cookies.");
    }
  };

// Helper function to handle navigation or page reload
  const navigateOrReload = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path, { state: { source: "integrationContext" }, replace: true });
    }
  };

// Helper function to handle authentication errors
  const handleAuthError = (error) => {
    if (error.code === "auth/popup-closed-by-user") {
      console.log("Authentication popup closed by the user.");
    } else {
      console.error(error);
    }
  };

  const handleDisconnectFromFB = async (FBAccID) => {
    try {
      const response = await disconnectFromFB(FBAccID);
      setIntegratedAlready(false);
      setShowCancelIntegrationModal(false);

      toast.success(response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });

      let user = Cookies.get("userData");
      const token = Cookies.get("access_token");
      Cookies.remove("userPages");
      if (user) {
        user = JSON.parse(user);
        if (token) {
          user.user["access-token"] = null;
          Cookies.set("userData", JSON.stringify(user));
          Cookies.remove("access_token");
          navigate("/integrations");
          if (window.location === "/integrations") {
            window.location.reload();
          }
        } else {
          console.log("Access token not found in user data.");
        }
      } else {
        console.log("User data not found in cookies.");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleDisconnectFromTiktok = async () => {
    try {
      const response = await tiktokService.disconnectFromTikTokAPI();
      setTiktokIntegrated(false);
      setShowCancelIntegrationModal2(false);
      console.log("Disconnection successful:", response);
      Cookies.remove("access_token_tiktok");
    } catch (error) {
      console.error("Error disconnecting from TikTok:", error);
    }
  };

  const handleFetchTikTokPages = async (advertiserID) => {
    setLoadingPages(true);
    try {
      const response = await tiktokService.getUserPagesTikTokAPI(advertiserID);
      setTiktokPages(response?.data?.data?.list);
    } catch (error) {
      console.error("Error fetching TikTok pages:", error);
    } finally {
      setLoadingPages(false); // Set loading state to false regardless of success or failure
    }
  };

  const handleDownloadLeadsTiktok = async (advertiserID, pageID) => {
    const response = await tiktokService.downloadTikTokLeadsAPI(advertiserID, pageID);
    console.log(response);
  };

  // Step 3: Provide the context values
  const contextValues = {
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    filteredData,
    setFilteredData,
    showIntegrationModal,
    setShowIntegrationModal,
    integrationData,
    accounts,
    setAccounts,
    handleSignInWithPlatform,
    handleDisconnectFromFB,
    showCancelIntegrationModal,
    setShowCancelIntegrationModal,
    showCancelIntegrationModal2,
    setShowCancelIntegrationModal2,
    setIntegratedAlready,
    integratedAlready,
    handleDisconnectFromTiktok,
    setTiktokIntegrated,
    tiktokIntegrated,
    advertisers,
    setAdvertisers,
    tiktokPages,
    setTiktokPages,
    selectedAdvertiser,
    setSelectedAdvertiser,
    loadingPages,
    setLoadingPages,
    handleFetchTikTokPages,
    handleDownloadLeadsTiktok,
    showTikTokAuthModal,
    setShowTikTokAuthModal,
    userPages,
    setUserPages,
  };

  return (
    <IntegrationContext.Provider value={contextValues}>
      {children}
    </IntegrationContext.Provider>
  );
};

// Custom hook to consume the context
export const useIntegrationContext = () => useContext(IntegrationContext);
