import { toast } from "react-toastify";

// Utility to handle success toast
const showSuccessToast = (message) => {
    toast.success(message, { position: "bottom-right", theme: "dark" });
};

// Utility to handle error toast
const showErrorToast = (message) => {
    toast.error(message, { position: "bottom-right", theme: "dark" });
};

export { showSuccessToast, showErrorToast };
