import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import DisconnectFromFBApi from "../services/auth/client";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const fbAuthProvider = new FacebookAuthProvider();
fbAuthProvider.addScope("ads_management");
fbAuthProvider.addScope("business_management");
fbAuthProvider.addScope('email');
fbAuthProvider.addScope("public_profile");
fbAuthProvider.addScope("pages_show_list");
fbAuthProvider.addScope("pages_manage_ads");
fbAuthProvider.addScope("leads_retrieval");
fbAuthProvider.addScope("pages_messaging");
fbAuthProvider.addScope("instagram_basic");
fbAuthProvider.addScope("instagram_manage_messages");
fbAuthProvider.addScope("pages_read_engagement");
fbAuthProvider.addScope("pages_manage_metadata");
fbAuthProvider.addScope("whatsapp_business_messaging");
fbAuthProvider.addScope("whatsapp_business_management");
fbAuthProvider.setCustomParameters({
  include_granted_scopes: true,
  prompt: "consent",
});

export const FacebookAuth = async () => {
  const customParams = { display: "popup" };
  fbAuthProvider.setCustomParameters(customParams);
  return signInWithPopup(auth, fbAuthProvider);
};

export const disconnectFromFB = async (FBAccID) => {
  await DisconnectFromFBApi(FBAccID);
};

export const googleProvider = new GoogleAuthProvider();
export const GoogleAuth = async () => {
  return signInWithPopup(auth, googleProvider);
};
