import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import LinkedInIcon from "../assets/media/Icons/linkedin.svg";
import FacebookIcon from "../assets/media/Icons/facebook.svg";
import SnapChatIcon from "../assets/media/Icons/snapchat.svg";
import GlobeIcon from "../assets/media/Icons/globe.svg";
import GoogleAdsIcon from "../assets/media/Icons/googleads.svg";
import TikTokIcon from "../assets/media/Icons/tiktok.svg";
import Instagram from "../assets/media/Icons/instagram.svg";
import whatsapp from "../assets/media/Icons/whatsapp.svg";
import phone from "../assets/media/Icons/phone.svg";
import { useAuth } from "./AuthContext";
import CreateTeamMemberApi from "../services/teams/create-team-member.api";
import clientService from "../services/clients";
import deleteTeamMemberApi from "../services/teams/delete-member.api";
import adminService from "../services/admin";
import leadService from "../services/leads";

// Create a context
const ClientContext = createContext();

// Create a context provider component
export const ClientProvider = ({ children }) => {
  const { user } = useAuth();
  const [leadStatusCounts, setLeadStatusCounts] = useState({
    completed: 0,
    inprogress: 0,
    pendding: 0,
    rejected: 0,
  });

  const [selectedSource, setSelectedSource] = useState(null);
  const [leads, setLeads] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [activeTab, setActiveTab] = useState("members");
  const [filterStatus, setFilterStatus] = useState("all");

  const handleSocialIconClick = (source) => {
    setSelectedSource(source);
  };

  const sourceToIcon = {
    1: FacebookIcon,
    2: GoogleAdsIcon,
    3: SnapChatIcon,
    4: TikTokIcon,
    5: Instagram,
    6: LinkedInIcon,
    7: phone,
    8: whatsapp,
    9: GlobeIcon,
  };

  const handleAddClient = async (values, onHide, resetForm) => {
    const autoAssign = values.auto_assign ? 1 : 0;
    const leadData = {
      ...values,
      client_id: values.client_id ? parseInt(values.client_id) : user.user.id,
      auto_assign: autoAssign,
    };

    try {
      const adminFlag = !!values.client_id;
      const submissionData = JSON.stringify(leadData);
      const result = await leadService.createLeadApi(submissionData, adminFlag);
      const newLead = result.data;
      setLeads((prevLeads) => [newLead, ...prevLeads]);
      toast.success("Lead Created Successfully!", {
        position: "bottom-right",
        theme: "dark",
      });
      resetForm();
      onHide();
    } catch (error) {
      toast.error(error.response.data.message || "An error occurred", {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleAssignTeamMember = async (leadId, memberId) => {
    try {
      const result = await leadService.updateSingleLeadApi(Number(leadId), {
        assigned_id: Number(memberId),
      });
      if (result.status === 200) {
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.id === leadId
              ? {
                  ...lead,
                  assignedTo: result?.data?.assigned_to?.name || result?.data?.assigned_to,
                  latestActivity:
                    result?.data?.activities[result.data.activities.length - 1],
                }
              : lead,
          ),
        );
        // increase the inprogress by one and decrease the pending by one
        setLeadStatusCounts((prevCounts) => ({
          ...prevCounts,
          inprogress: prevCounts.inprogress + 1,
          pendding: prevCounts.pendding - 1,
        }));
        toast.success(result.message, {
          position: "bottom-right",
          theme: "dark",
        });
      } else {
        toast.error("Failed to assign team member.", {
          position: "bottom-right",
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error assigning team member:", error);
      toast.error("Error assigning team member. Please try again.", {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleUnAssignedTeamMember = async (leadId) => {
    try {
      const result = await leadService.unassignedLeadsApi(leadId);
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === leadId
            ? {
                ...lead,
                assignedTo: null,
                latestActivity:
                  result?.data?.activities[result.data.activities.length - 1],
              }
            : lead,
        ),
      );
      toast.success("Lead Unassigned Successfully", {
        position: "bottom-right",
        theme: "dark",
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };
  const handleAutoAssign = async (status) => {
    await leadService.autoAssignLeadsApi(status);
  };

  const handleAddTeamMember = async (values, handleClose, resetForm) => {
    const trimmedValues = {
      parent_id: user.user.id,
      name: values.name,
      email: values.email.trim(),
      phone: values.phone.trim(),
      status: "active",
      password: values.password.trim(),
      role: Number(values.role),
    };
    try {
      const res = await CreateTeamMemberApi(trimmedValues);
      if (res && res.status === 200) {
        toast.success("Team member added successfully", {
          position: "bottom-right",
          theme: "dark",
        });
        handleClose();
        if (user.user.role === 0) {
          setTeamMembers((prevTeamMembers) => [
            res.data.user,
            ...prevTeamMembers,
          ]);
        }
        resetForm();
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleAddAdmin = async (values, handleClose, resetForm) => {
    const trimmedValues = {
      ...values,
      name: values.name,
      email: values.email.trim(),
      phone: values.phone.trim(),
      password: values.password.trim(),
    };
    try {
      const res = await adminService.createAdminMember(trimmedValues);
      if (res && res.status === 200) {
        toast.success("Team member added successfully", {
          position: "bottom-right",
          theme: "dark",
        });
        handleClose();
        if (
          user.user.role === 0 ||
          user.user.role === 6 ||
          user.user.role === "Owner"
        ) {
          setTeamMembers((prevTeamMembers) => [
            res.data.Admin,
            ...prevTeamMembers,
          ]);
        }
        resetForm();
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleExportLeads = async (clientId) => {
    try {
      await leadService.exportLeadsApi(clientId);
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleExportClients = async () => {
    try {
      await clientService.exportClientsApi();
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleImportLeads = async (data) => {
    try {
      if (data) {
        const result = await leadService.importLeads(data);
        if (result.status === 200) {
          toast.success("File uploaded successfully", {
            position: "bottom-right",
            theme: "dark",
          });
          if (user.user.role === 0) {
            await leadService.getAllLeadsApi();
          }
          window.location.reload();
        }
      }
    } catch (error) {
      toast.error(`Error uploading file: ${error.message}`, {
        position: "bottom-right",
        theme: "dark",
      });
      toast.error("Couldn't get leads. Please reload the page.", {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleAdminTeamMemberStatus = async (id, status) => {
    await leadService.updateAdminMemberStatusApi(id);
    setTeamMembers((prevTeamMembers) =>
      prevTeamMembers.map((teamMember) =>
        teamMember.id === id ? { ...teamMember, status } : teamMember,
      ),
    );
  };

  const handleDeleteTeamMember = async (
    teamMemberId,
    handleClose,
    afterDelete,
  ) => {
    try {
      const response = await deleteTeamMemberApi(teamMemberId);
      setTeamMembers((prevTeamMembers) =>
        prevTeamMembers.filter((teamMember) => teamMember.id !== teamMemberId),
      );
      handleClose();
      toast.success(response.message, {
        position: "bottom-right",
        theme: "dark",
      });
      if (afterDelete) afterDelete();
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  const handleDeleteAdminTeamMember = async (teamMemberId, handleClose) => {
    try {
      const response = await leadService.deleteAdminMemberApi(teamMemberId);
      setTeamMembers((prevTeamMembers) =>
        prevTeamMembers.map((teamMember) =>
          teamMember.id === teamMemberId
            ? { ...teamMember, status: "deactivated" } // Update the status of the deleted team member
            : teamMember,
        ),
      );
      handleClose();
      toast.success(response.message, {
        position: "bottom-right",
        theme: "dark",
      });
    } catch (e) {
      toast.error(e?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  // Define the context value
  const contextValue = {
    selectedSource,
    setSelectedSource,
    leads,
    setLeads,
    teamMembers,
    setTeamMembers,
    sourceToIcon,
    leadStatusCounts,
    setLeadStatusCounts,
    handleSocialIconClick,
    handleAddClient,
    handleAssignTeamMember,
    handleAddTeamMember,
    handleAutoAssign,
    handleExportLeads,
    handleImportLeads,
    handleAddAdmin,
    handleExportClients,
    // handleAdminTeamMemberStatus,
    handleAdminTeamMemberStatus,
    handleDeleteTeamMember,
    handleDeleteAdminTeamMember,
    handleUnAssignedTeamMember,
    activeTab,
    setActiveTab,
    filterStatus,
    setFilterStatus
  };
  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

// Create a custom hook for using the context
export const useClientContext = () => {
  return useContext(ClientContext);
};
