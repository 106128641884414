import { createContext, useContext, useRef, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase.config";
import metaService from "../services/integrations/meta";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useAuth } from "./AuthContext";

const MetaBusinessChatContext = createContext();

export const MetaBusinessChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [latestMessages, setLatestMessages] = useState(null);
  const [nestedMessages, setNestedMessages] = useState([]);
  const [messagesSnapshotUnsubscribe, setMessagesSnapshotUnsubscribe] =
    useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredChats, setFilteredChats] = useState([]);
  const [whatsappChats, setWhatsappChats] = useState([]);
  const [whatsappChatMessages, setWhatsappChatMessages] = useState([]);
  const [whatsAppAccounts, setWhatsAppAccounts] = useState([]);
  const [selectedWhatsAccount, setSelectedWhatsAccount] = useState(null);
  const [whatsAppAccountDetails, setWhatsAppAccountDetails] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [businessPhoneNumbers, setBusinessPhoneNumbers] = useState([]);
  const [disabledChat, setDisabledChat] = useState(false);
  const [pageImg, setPageImg] = useState(null);
  const [paginationMeta, setPaginationMeta] = useState([null, null]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const { user } = useAuth();
  const accessToken =
    user?.user?.["access-token"] || Cookies.get("access_token");
  const fetchLatestMessages = async () => {
    try {
      const messagesQuery = query(
        collection(db, "chats"),
        orderBy("created_time", "asc"),
      );
      return onSnapshot(messagesQuery, (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLatestMessages(updatedMessages);
      });
    } catch (error) {
      console.error("Error fetching latest messages:", error);
    }
  };

  const extractLatestMessage = (chatId) => {
    const senderMessage = latestMessages?.find(
      (chat) => chat?.sender?.toString() === chatId?.toString(),
    );
    const recipientMessage = latestMessages?.find(
      (chat) => chat?.recipient?.toString() === chatId?.toString(),
    );
    let latestMessage;
    if (senderMessage && recipientMessage) {
      latestMessage =
        new Date(senderMessage.created_time) >
        new Date(recipientMessage.created_time)
          ? senderMessage
          : recipientMessage;
    } else {
      latestMessage = senderMessage || recipientMessage;
    }

    return {
      ...latestMessage,
      message: latestMessage?.message,
      created_time: latestMessage?.created_time || latestMessage?.updated_time,
    };
  };
  const fetchMessagesAbortController = useRef(null);
  const fetchMessages = async (thread) => {
    setNestedMessages([]);
    if (!thread) {
      setMessages([]);
      return;
    }

    if (fetchMessagesAbortController.current) {
      fetchMessagesAbortController.current.abort();
    }
    fetchMessagesAbortController.current = new AbortController();
    const signal = fetchMessagesAbortController.current.signal;

    try {
      const result = await metaService.getMessagesForChatApi({
        ...selectedPage,
        access_token: selectedPage?.page_token,
        user_id: thread.id,
        signal,
      });

      result.sort(
        (a, b) => new Date(a.created_time) - new Date(b.created_time),
      );
      setNestedMessages(result);

      const senderId =
        thread?.flage === "instagram"
          ? thread?.participants?.data[1]?.id
          : thread?.participants?.data[0]?.id;
      const messageFromFirestore = latestMessages
        ? extractLatestMessage(senderId)
        : null;

      if (messageFromFirestore && messageFromFirestore.id) {
        const nestedCollectionRef = collection(
          db,
          "chats",
          messageFromFirestore.id,
          "messages",
        );
        const nestedQuery = query(
          nestedCollectionRef,
          orderBy("created_time", "desc"),
        );
        const unsubscribe = onSnapshot(nestedQuery, (snapshot) => {
          const nestedMessages = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              created_time: data.created_time,
              from: {
                id: Number(data.sender),
                email: Number(data.sender),
              },
              message: data.message,
            };
          });

          setMessages((prevMessages) => {
            const uniqueMessages = [...prevMessages, ...nestedMessages].reduce(
              (acc, message) => {
                if (!acc.some((m) => m.id === message.id)) {
                  acc.push(message);
                }
                return acc;
              },
              [],
            );

            uniqueMessages.sort(
              (a, b) => new Date(a.created_time) - new Date(b.created_time),
            );
            return uniqueMessages;
          });
        });

        setMessagesSnapshotUnsubscribe(() => unsubscribe);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch messages request aborted");
      } else {
        console.error("Error fetching messages:", error);
      }
    }
  };

  const sendWhatsMessage = async (data) => {
    try {
      const response = await metaService.sendWhatsMessageApi(data);
      toast.success(response?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    } catch (error) {
      console.error("Error sending WhatsApp message:", error);
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
        theme: "dark",
      });
    }

    // const currentDate = new Date();
    // const newMessage = {
    //   id: response.message_id, // Use the message_id from the response as the ID
    //   recipient:
    //     selectedWhatsAccount?.phone_numbers?.data[0].display_phone_number.trim(),
    //   sender: process.env.WHATS_APP_BUSINESS_PHONE,
    //   message: data?.text?.body,
    //   created_time: currentDate,
    // };
    //
    // const messageRef = doc(
    //   db,
    //   "whatsApp",
    //   selectedWhatsAccount.id,
    //   "messages",
    //   newMessage.id,
    // );
    // await setDoc(messageRef, newMessage);
    // setWhatsappChatMessages((prevWhatsMessage) => {
    //   return { ...prevWhatsMessage, newMessage };
    // });
  };

  const sendMessage = async (data) => {
    const response = await metaService.sendMessageApi(data);
    if (response?.error && response?.error?.message?.includes("(#10)")) {
      setDisabledChat(true);
      return;
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace("Z", "+0000");
    const recipient =
      selectedChat?.flage === "instagram"
        ? selectedChat?.participants?.data[1]?.id
        : selectedChat?.participants?.data[0]?.id ||
          selectedChat?.participants?.data[0]?.id;
    const sender =
      selectedChat?.flage === "instagram"
        ? selectedChat?.participants?.data[0]?.id
        : selectedChat?.participants?.data[1]?.id ||
          selectedChat?.participants?.data[0]?.id;
    const newMessage = {
      id: response.message_id, // Use the message_id from the response as the ID
      recipient: recipient,
      from: {
        id: sender,
        name:
          selectedChat?.flage === "instagram"
            ? selectedChat?.participants?.data[0]?.name
            : selectedChat?.participants?.data[1]?.name ||
              selectedChat?.participants?.data[0]?.name,
        email:
          selectedChat?.flage === "instagram"
            ? selectedChat?.participants?.data[0]?.email
            : selectedChat?.participants?.data[1]?.email ||
              selectedChat?.participants?.data[0]?.email,
      },
      message: data.message,
      created_time: currentDate,
    };

    try {
      const messageRef = doc(db, "chats", sender, "messages", newMessage.id);
      await setDoc(messageRef, newMessage);
    } catch (error) {
      console.error("Error setting document:", error);
    }

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Update the latestMessages state
    setLatestMessages((prevLatestMessages) => {
      return prevLatestMessages.map((chat) => {
        if (chat.id === selectedChat.id) {
          return {
            ...chat,
            message: newMessage.message,
            created_time: newMessage.created_time,
          };
        }
        return chat;
      });
    });

    // setSelectedChat((prevSelectedChat) => {
    //   return { ...prevSelectedChat, created_time: formattedDate };
    // });

    // setChats((prevChats) => {
    //     const updatedChats = prevChats.map((chat) => {
    //         if (chat.id === selectedChat.id) {
    //             return { ...chat, created_time: updatedTime };
    //         }
    //         return chat;
    //     });
    //     // Sort the updated chats by created_time
    //     return updatedChats.sort(
    //         (a, b) => new Date(b.created_time) - new Date(a.created_time)
    //     );
    // });

    setFilteredChats((prevChats) => {
      const updatedChats = prevChats.map((chat) => {
        if (chat.id === selectedChat.id) {
          return { ...chat, created_time: formattedDate };
        }
        return chat;
      });
      // Sort the updated chats by created_time
      return updatedChats.sort(
        (a, b) => new Date(b.created_time) - new Date(a.created_time),
      );
    });

    // Update the Firestore document for the latest message
    const latestMessageRef = doc(db, "chats", sender);
    await setDoc(
      latestMessageRef,
      {
        message: newMessage.message,
        // created_time: newMessage.created_time,
        created_time: formattedDate,
        sender: sender,
        recipient: recipient,
      },
      { merge: true },
    );
  };

  const selectChat = async (thread) => {
    setSelectedChat(thread);
    setMessages([]);
    setNestedMessages([]);
    setDisabledChat(false);
    if (messagesSnapshotUnsubscribe) {
      messagesSnapshotUnsubscribe();
      setNestedMessages([]);
      setMessagesSnapshotUnsubscribe(null);
    }
    //
    // Delete the nested "messages" collection for the selected chat
    try {
      if (thread && thread.id) {
        const chatId1 = thread?.participants?.data[1]?.id;
        const chatId2 = thread?.participants?.data[0]?.id;
        const nestedCollectionRef1 = collection(
          db,
          "chats",
          chatId1,
          "messages",
        );
        const nestedCollectionRef2 = collection(
          db,
          "chats",
          chatId2,
          "messages",
        );

        const nestedDocs1 = await getDocs(nestedCollectionRef1);
        const nestedDocs2 = await getDocs(nestedCollectionRef2);

        const deletePromises1 = nestedDocs1.docs.map((doc) =>
          deleteDoc(doc.ref),
        );
        const deletePromises2 = nestedDocs2.docs.map((doc) =>
          deleteDoc(doc.ref),
        );

        await Promise.all([...deletePromises1, ...deletePromises2]);
      }
    } catch (error) {
      console.error("Error deleting nested message collection:", error);
    }

    // Fetch messages for the selected chat
    await fetchMessages(thread);
  };

  const selectWhatsappChat = async (thread) => {
    selectedWhatsAccount(thread);
    setSelectedChat(null);
    // onsnapshot nested collection "messages" inside the selected chat
    const messagesQuery = query(
      collection(db, "whatsApp", thread?.id, "messages"),
      orderBy("created_time", "asc"),
    );
    return onSnapshot(messagesQuery, (snapshot) => {
      const whatsappChatMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWhatsappChatMessages(whatsappChatMessages);
    });
  };

  const formatDate = (timestamp) => {
    let messageDate;
    if (timestamp?.seconds && timestamp?.nanoseconds) {
      const milliseconds =
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
      messageDate = new Date(milliseconds);
    } else {
      try {
        messageDate = new Date(timestamp);
      } catch (error) {
        console.error("Error parsing timestamp:", error);
      }
    }

    const currentDate = new Date();
    return formatTimestamp(messageDate, currentDate);
  };

  const formatTimestamp = (timestamp, currentDate) => {
    const diffInDays = Math.floor(
      (currentDate - timestamp) / (1000 * 60 * 60 * 24),
    );

    if (diffInDays >= 3) {
      return timestamp.toLocaleDateString();
    } else if (diffInDays === 1) {
      return "Yesterday";
    } else if (timestamp.toDateString() === currentDate.toDateString()) {
      const hours = timestamp.getHours().toString().padStart(2, "0");
      const minutes = timestamp.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    } else {
      return timestamp.toLocaleDateString();
    }
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    if (filter === "all") {
      setFilteredChats(chats);
      setSelectedWhatsAccount(null);
    } else if (filter === "messenger") {
      setFilteredChats(chats.filter((chat) => chat.flage !== "instagram"));
      setSelectedWhatsAccount(null);
    } else if (filter === "instagram") {
      setFilteredChats(chats.filter((chat) => chat.flage === "instagram"));
      setSelectedWhatsAccount(null);
    }
  };

  const fetchWhatsAppChats = () => {
    try {
      const messagesQuery = query(
        collection(db, "whatsApp"),
        orderBy("created_time", "asc"),
      );
      return onSnapshot(messagesQuery, (snapshot) => {
        const whatsappChats = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWhatsappChats(whatsappChats);
      });
    } catch (error) {
      console.error("Error fetching latest messages:", error);
    }
  };

  const parsePhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return "+" + numericPhoneNumber;
  };

  const handleSelectWhatsAppAccount = (whatsAppAccount) => {
    setSelectedWhatsAccount(whatsAppAccount);
    setBusinessPhoneNumbers(
      whatsAppAccount?.phone_numbers?.data?.map((account) => ({
        ...account,
        phone: parsePhoneNumber(account?.display_phone_number),
      })),
    );
  };

  const resetFilteredChatsPages = () => {
    // setFilteredChats([]);
    // setUserPages([]);
    // setSelectedChat(null);
    // setSelectedPage(null);
    setNestedMessages([]);
    setMessages([]);
  };

  const fetchMoreChats = async ({ signal }) => {
    setLoadingPagination(true);

    // Filter out null and "null" string values from paginationMeta before constructing the URL array
    const urlParams = [paginationMeta[0], paginationMeta[1]].filter(
      (item) => item !== null && item !== "null",
    );

    if (urlParams.length === 0) {
      console.log("No more pages to fetch.");
      setLoadingPagination(false);
      return null; // Explicitly return null when there are no more pages
    }

    try {
      const result = await metaService.getMoreChatsAPI({
        url: urlParams,
        access_token: selectedPage?.page_token,
        signal,
      });

      if (result?.data) {
        const {
          data,
          insta_next: newInstaNext,
          messanger_next: newMessengerNext,
        } = result.data;

        // Update nextPage with new pagination tokens
        setPaginationMeta(
          [newInstaNext, newMessengerNext].filter(
            (item) => item !== null && item !== "null",
          ),
        );

        // Merge previous chats with newly fetched chats
        setChats((prevChats) => {
          const allChats = [...prevChats, ...data];

          // Sort all chats by updated_time
          return allChats.sort(
            (a, b) =>
              new Date(b.updated_time).getTime() -
              new Date(a.updated_time).getTime(),
          );
        });

        // Update filteredChats with the same sorted list
        setFilteredChats((prevChats) => {
          const allChats = [...prevChats, ...data];

          // Sort all chats by updated_time
          // const sortedChats = allChats.sort(
          //   (a, b) =>
          //     new Date(b.updated_time).getTime() -
          //     new Date(a.updated_time).getTime(),
          // );

          // Filter based on activeFilter
          if (activeFilter === "all") {
            return allChats;
          } else if (activeFilter === "messenger") {
            return allChats.filter((chat) => chat.flage !== "instagram");
          } else {
            return allChats.filter((chat) => chat.flage === "instagram");
          }
        });
        setLoadingPagination(false);
        return result;
      } else {
        console.error("No data in API result:", result);
        setLoadingPagination(false);
        return null;
      }
    } catch (error) {
      console.error("Error fetching more chats:", error);
      setLoadingPagination(false);

      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch failed with error:", error);
      }

      return null;
    }
  };

  return (
    <MetaBusinessChatContext.Provider
      value={{
        chats,
        selectedChat,
        filteredChats,
        setFilteredChats,
        messages: [...(nestedMessages || null), ...messages],
        sendMessage,
        selectChat,
        setMessages,
        setChats,
        selectedPage,
        setSelectedPage,
        setSelectedChat,
        formatDate,
        fetchLatestMessages,
        extractLatestMessage,
        latestMessages,
        setLatestMessages,
        fetchMessages,
        setNestedMessages,
        activeFilter,
        setActiveFilter,
        handleFilterChange,
        fetchWhatsAppChats,
        whatsappChats,
        setWhatsappChats,
        whatsappChatMessages,
        setWhatsappChatMessages,
        selectWhatsappChat,
        sendWhatsMessage,
        whatsAppAccounts,
        setWhatsAppAccounts,
        selectedWhatsAccount,
        setSelectedWhatsAccount,
        whatsAppAccountDetails,
        setWhatsAppAccountDetails,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        filteredData,
        setFilteredData,
        conversations,
        setConversations,
        handleSelectWhatsAppAccount,
        businessPhoneNumbers,
        resetFilteredChatsPages,
        disabledChat,
        setDisabledChat,
        pageImg,
        setPageImg,
        paginationMeta,
        setPaginationMeta,
        hasMore,
        setHasMore,
        fetchMoreChats,
        loadingPagination,
        setLoadingPagination,
        loadingChats,
        setLoadingChats,
      }}
    >
      {children}
    </MetaBusinessChatContext.Provider>
  );
};

export const useMetaBusinessChatContext = () =>
  useContext(MetaBusinessChatContext);
