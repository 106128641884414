import apiRequest from "../../utils/apiRequest";

const updateSingleRoleApi = async (roleId, selectedPermissions) => {
    const updatedData = {
        permission: selectedPermissions,
    };
    return await apiRequest(`update_permissions/${roleId}`, "post", updatedData);
};

export default updateSingleRoleApi;
